import request from '@/utils/request'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  async shipmentOrder({ rootState }, payload) {
    request({
      url: `/api-orquestador/v2.0/falabella/shipmentOrder`,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: payload
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
